@import url('./fonts.css');

/* ---------------------------------------------------------------------------- */

.image_text_container {
    display: flex;
    width: 100vw; /* Ensure the container takes up the full width */
    height: 80vh; /* Ensure the container takes up the full width */
}

/* ---------------------------------------------------------------------------- */
.logo_agent1 {
    max-width: 70vw; /* Ensure the image does not exceed the container width */
    max-height: 40vh; /* Ensure the image does not exceed the container width */
    padding-left: 15vw;
    padding-top: 15vh;
}

.h_agent1 {
    background-color: #000;
    color: #fff;
    padding: 3vw;
    font-family: 'Garet'; /* Make sure the font family name is correct */
    margin-top: 15vh;
    max-width: 30vw; /* Ensure the image does not exceed the container width */
    max-height: 40vh; /* Ensure the image does not exceed the container width */
}

.p_agent1 {
    background-color: #fff;
    font-family: 'Garet'; /* Make sure the font family name is correct */
    max-width: 30vw; /* Ensure the image does not exceed the container width */
    max-height: 40vh; /* Ensure the image does not exceed the container width */
    padding: 5%;
}

/* ---------------------------------------------------------------------------- */
.logo_agent2 {
    width: 40vw; /* Ensure the image does not exceed the container width */
    height: 40vh; /* Ensure the image does not exceed the container width */
    padding-left: 15vw;
    padding-top: 15vh;
}

.h_agent2 {
    background-color: #000;
    color: #fff;
    padding: 3vw;
    margin: 0;
    font-family: 'Garet'; /* Make sure the font family name is correct */
    margin-top: 15vh;
    max-width: 30vw; /* Ensure the image does not exceed the container width */
    max-height: 40vh; /* Ensure the image does not exceed the container width */
}

.p_agent2 {
    margin: 0%;
    background-color: #fff;
    font-family: 'Garet'; /* Make sure the font family name is correct */
    max-width: 30vw; /* Ensure the image does not exceed the container width */
    max-height: 40vh; /* Ensure the image does not exceed the container width */
    padding: 10%;
}
/* ---------------------------------------------------------------------------- */
.logo_agent3 {
    width: 40vw; /* Ensure the image does not exceed the container width */
    height: 40vh; /* Ensure the image does not exceed the container width */
    padding-left: 15vw;
    padding-top: 15vh;
}

.h_agent3 {
    background-color: #000;
    color: #fff;
    padding: 3vw;
    margin: 0;
    font-family: 'Garet'; /* Make sure the font family name is correct */
    margin-top: 15vh;
    max-width: 30vw; /* Ensure the image does not exceed the container width */
    max-height: 40vh; /* Ensure the image does not exceed the container width */
}

.p_agent3 {
    margin: 10%;
    background-color: #fff;
    font-family: 'Garet'; /* Make sure the font family name is correct */
    max-width: 30vw; /* Ensure the image does not exceed the container width */
    max-height: 40vh; /* Ensure the image does not exceed the container width */
    padding: 5%;
}
/* ---------------------------------------------------------------------------- */

.menu-button:hover {
    font-weight: bolder;
}

.coming_soon_banner {
    padding-top: 15%;
    font-family: 'Garet'; /* Make sure the font family name is correct */
    font-size: 70px;
}