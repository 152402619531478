/* App.css */
@import url('./fonts.css');

body {
  margin: 0;
  padding: 0;
  background-color: #fff; /* White background */
}

.App {
  text-align: center;
}

.App-header {
  background-color: #fff; /* Dark background for the header */
  padding: 20px;
  display: flex;
  font-weight: bolder;
  justify-content: space-between;
  align-items: center;
}

.logo {
  height: 50px; /* Adjust the height as needed */
}

.menu {
  display: flex;
}

.menu-button {
  background-color: transparent;
  color: #000;
  border: none;
  padding: 10px 20px;
  margin-right: 10px;
  font-size: 16px;
  cursor: pointer;
  font-family: Garet;
}

.menu-button:hover {
  font-weight: bolder;
}
